import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import MainLayout from "../layout/mainLayout";
import RichText from "../text/richText";

const KontentItemRichTextModulesSetup = ({ data }) => (
  <MainLayout>

    <RichText data={data.page.elements.all_in_one} isArticle />

  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    page: kontentItemRichTextModulesSetup(id: {eq: $id}) {
      id
      elements {
        all_in_one {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            internal {
              type
            }
            system {
              codename
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemStatementboxGroup
            ...KontentItemEmbedExternal
          }
        }
      }
    }
  }
`;

KontentItemRichTextModulesSetup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemRichTextModulesSetup;
